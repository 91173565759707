<template>
  <li class="dp-pro-recipe-step-list-item">
    <span>{{ content }}</span>
  </li>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    content: {
      type: String,
      default: null
    }
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-pro-recipe-step-list-item {
  line-height: 1.6;
  word-break: break-word;
  white-space: pre-wrap;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
